body{
	overflow-x: hidden;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
header{
	margin-bottom: 0px;
}
.title{
	font-weight: bold !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.table-header{
	background-color: #ECF0F1;
}
.activemenu{
	background-color: #d3dcde;
}

.light-border{
	border:1;
	border-color: lightgray;
}
.printicon{
	color:yellow;
	margin-left: 8px;
	font-weight: bold;
}
.printicon:hover{
	cursor: pointer;
}

.subtitle{
	color: #fff !important;
	font-size: small;
	font-style: italic;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
}

/*@media print {
	.tprint {
		width: 100% !important;
	}
	@page { size: 80mm 50mm }
 }*/
 /*@media print {
	@page {
	  size: 60mm 80mm !important;
	}
  }*/
 .cancelIcon{
	cursor: pointer;
	font-size: 20px;
	color: red;
 }
.navbar{
	padding : 0 !important;
}
.page-title{
	background-color: #d3dcde;
	padding: 5px;
	text-align: center;
	font-weight: 500 !important;
	margin-bottom: 15px !important;
}
.centered{
	color: #fff;
	font-size: xx-large;
}
.slider_section{
	margin-top: 30px;
}
.loginForm{
	padding: 20px;
}
.loginbtn{
	display: inline-block;
    padding: 6px 0;
    background-color: #d8bf36;
    color: #ffffff;
    border-radius: 30px;
	width: 125px;
	text-align: center;
}

@media only screen and (min-device-width : 330px) and (max-device-width : 540px) {
	.btn-box{
		min-height: 0;
	}
	.slider_section .detail_box h1 {
		color: #fff;
		font-size: 2rem !important;
		font-weight: bold;
		margin-bottom: 10px;
	}
}